@import '../../node_modules/@britannica/ui/src/scss/functions';

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// test comment added on december 9 to test a pull request by eileen

// --- Options --- //

// $enable-important-utilities: false !default;

// --- Color system --- //

// Grayscale

// $gray-50: #f2f2f2 !default;
// $gray-100: #e6e6e6 !default;
// $gray-200: #ccc !default;
// $gray-300: #b3b3b3 !default;
// $gray-400: #999 !default;
// $gray-500: #808080 !default;
// $gray-600: #666 !default;
// $gray-700: #4d4d4d !default;
// $gray-800: #333 !default;
$gray-900: #1a1a1a !default;

// $grays: (
//   'gray-50': $gray-50,
//   'gray-100': $gray-100,
//   'gray-200': $gray-200,
//   'gray-300': $gray-300,
//   'gray-400': $gray-400,
//   'gray-500': $gray-500,
//   'gray-600': $gray-600,
//   'gray-700': $gray-700,
//   'gray-800': $gray-800,
//   'gray-900': $gray-900,
// ) !default;

// Colors

// $navy: #0e365a !default;
// $navy-dark: #071c2e !default;
// $blue: #14599d !default;
// $blue-dark: #0e3f70 !default;
// $green: #00794a !default;
// $green-dark: #00462b !default;
// $red: #9a2222 !default;
// $red-dark: #701919 !default;
// $orange: #f87307 !default;
// $orange-dark: darken($orange, 10%) !default;
// $purple: #331f5f !default;
// $purple-dark: #1e1239 !default;
// $yellow: #ffc615 !default;
// $yellow-light: #fefec6 !default;
// $white: #fff !default;
// $black: #000 !default;
// $opaque: rgba($white, 0.2) !default;

// $colors: (
//   'navy': $navy,
//   'navy-dark': $navy-dark,
//   'blue': $blue,
//   'blue-dark': $blue-dark,
//   'green': $green,
//   'green-dark': $green-dark,
//   'red': $red,
//   'red-dark': $red-dark,
//   'orange': $orange,
//   'orange-dark': $orange-dark,
//   'purple': $purple,
//   'purple-dark': $purple-dark,
//   'yellow': $yellow,
//   'yellow-light': $yellow-light,
//   'white': $white,
//   'black': $black,
//   'opaque': $opaque,
// ) !default;

// Content types

// $list: $navy !default;
// $quiz: $navy !default;
// $demystified: $green !default;
// $spotlight: $navy !default;
// $wtfact: $yellow !default;
// $gallery: $navy !default;

// $content-type-colors: (
//   'list': $list,
//   'quiz': $quiz,
//   'demystified': $demystified,
//   'spotlight': $spotlight,
//   'wtfact': $wtfact,
//   'gallery': $gallery,
// ) !default;

// Social

// $facebook: #4267b2 !default;
// $flickr: #ff0084 !default;
// $google: #dd4b39 !default;
// $instagram: #3897f0 !default;
// $linkedin: #0077b5 !default;
// $pinterest: #e60023 !default;
// $twitter: #00aced !default;
// $yahoo: #720e9e !default;
// $youtube: #c00 !default;

// $social-colors: (
//   'facebook': $facebook,
//   'flickr': $flickr,
//   'google': $google,
//   'instagram': $instagram,
//   'linkedin': $linkedin,
//   'pinterest': $pinterest,
//   'twitter': $twitter,
//   'yahoo': $yahoo,
//   'youtube': $youtube,
// ) !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
// $min-contrast-ratio: 3 !default;

// Customize the light and dark text colors for use in our color contrast function.
// $color-contrast-dark: $gray-900 !default;
// $color-contrast-light: $white !default;

// --- Spacing --- //

// $spacers: (
//   0: 0,
//   5: 5px,
//   10: 10px,
//   15: 15px,
//   20: 20px,
//   25: 25px,
//   30: 30px,
//   35: 35px,
//   40: 40px,
//   45: 45px,
//   50: 50px,
//   55: 55px,
//   60: 60px,
//   100: 100px,
// ) !default;

// $negative-spacers: negativify-map($spacers) !default;

// Characters which are escaped by the escape-svg function
// $escaped-characters: (('<', '%3c'), ('>', '%3e'), ('#', '%23'), ('(', '%28'), (')', '%29')) !default;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// $zindex-dropdown: 1000 !default;
// $zindex-sticky: 1020 !default;
// $zindex-fixed: 1030 !default;
// $zindex-modal-backdrop: 1040 !default;
// $zindex-modal: 1050 !default;
// $zindex-popover: 1060 !default;
// $zindex-tooltip: 1070 !default;

// --- Body --- //
//
// Settings for the `<body>` element.

// $body-bg: $white !default;
// $body-color: $gray-900 !default;
// $body-text-align: null !default;

// --- Links --- //
//
// Style anchor elements.

// $link-color: $blue !default;
// $link-decoration: none !default;
// $link-hover-color: darken($link-color, 15%) !default;
// $link-hover-decoration: underline !default;
// Darken percentage for links with `.link-*` class (e.g. `.link-white`)
// $emphasized-link-hover-darken-percentage: 15% !default;

// --- Grid system --- //

// Grid columns

// $grid-columns: 20 !default;
// $grid-columns-one-off-widths: (16.66666, 33.33333, 66.66666) !default;
// $grid-columns-ad-widths: (160, 200, 300) !default;

// Gutters

// $grid-gutter-width: 20px !default;

// $gutters: $spacers !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// $grid-breakpoints: (
//   xs: 0,
//   sm: 672px,
//   md: 960px,
//   lg: 1248px,
//   xl: 1400px,
// ) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// $container-max-widths: (
//   xs: 1140px,
// ) !default;

// Container padding

// $container-padding-x: $grid-gutter-width !default;

// --- Component variables --- //

// $border-width: 1px !default;
// $border-color: $gray-100 !default;

// $border-radius: 0.3rem !default;
// $border-radius-sm: 0.2rem !default;
// $border-radius-lg: 0.3rem !default;

// $box-shadow-sm: 0 0 10px rgba($black, 0.075) !default;
// $box-shadow: 0 0 10px rgba($black, 0.15) !default;
// $box-shadow-lg: 0 0 10px rgba($black, 0.3) !default;

// $component-active-color: $white !default;
// $component-active-bg: $blue-dark !default;

// --- Typography --- //

// $font-family-sans-serif: -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', 'Segoe UI', Arial, sans-serif !default;
$font-family-serif: Georgia, serif !default;
// $font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;

// $font-family-base: $font-family-sans-serif;

// $font-size-base: 1rem !default; // 16
// $font-size-xs: 0.75rem !default; // 12
// $font-size-sm: 0.875rem !default; // 14
// $font-size-lg: 1.125rem !default; // 18
// $font-size-xl: 1.25rem !default; // 20

// $font-sizes: (
//   12: $font-size-xs,
//   14: $font-size-sm,
//   16: $font-size-base,
//   18: $font-size-lg,
//   20: $font-size-xl,
// ) !default;

// $font-weight-light: 300 !default;
// $font-weight-normal: 400 !default;
// $font-weight-semi-bold: 500 !default;
// $font-weight-bold: 700 !default;
// $font-weight-extra-bold: 800 !default;

// $font-weight-base: $font-weight-normal !default;

// $h1-font-size: 30px !default;
// $h2-font-size: 24px !default;
// $h3-font-size: 20px !default;
// $h4-font-size: 18px !default;
// $h5-font-size: 16px !default;
// $h6-font-size: $font-size-base !default;

// $headings-margin-bottom: 0 !default;
// $headings-font-family: null !default;
// $headings-font-style: null !default;
// $headings-font-weight: 700 !default;
// $headings-line-height: 1.2 !default;
// $headings-color: null !default;

// $display1-size: 2.8rem !default;
// $display2-size: 2.25rem !default;
// $display3-size: 2rem !default;
// $display4-size: 2rem !default;

// $display1-weight: 700 !default;
// $display2-weight: 700 !default;
// $display3-weight: 700 !default;
// $display4-weight: 700 !default;
// $display-line-height: $headings-line-height !default;

// $line-height-base: 1.4 !default;
// $line-height-sm: 1.2 !default;
// $line-height-lg: 1.8 !default;

// $small-font-size: 0.875em !default;

// $letter-spacing-normal: normal !default;
// $letter-spacing-lg: 0.05em !default;

// $hr-margin-y: map-get($spacers, 10) !default;
// $hr-color: inherit !default;
// $hr-height: $border-width !default;
// $hr-opacity: 0.1 !default;

// $legend-margin-bottom: 0.5rem !default;
// $legend-font-size: 1.5rem !default;
// $legend-font-weight: null !default;

// $mark-padding: 0.2em !default;
// $mark-bg: $yellow-light !default;

// $paragraph-margin-bottom: 1rem !default;

// $dt-font-weight: $font-weight-bold !default;

// $nested-kbd-font-weight: $font-weight-bold !default;

// --- Components --- //

// Breadcrumbs

// $breadcrumb-font-size: $font-size-xs !default;
// $breadcrumb-letter-spacing: null !default;

// $breadcrumb-font-weight: null !default;
// $breadcrumb-text-transform: null !default;

// $breadcrumb-divider-icon: 'keyboard_arrow_right' !default;
// $breadcrumb-divider-weight: $font-weight-normal !default;

// $breadcrumb-item-padding-x: 5px !default;

// $breadcrumb-margin-bottom: 10px !default;

// Cards

// $card-font-size: $font-size-base !default;
// $card-box-shadow: $box-shadow !default;
// $card-padding-x: $grid-gutter-width !default;
// $card-padding-y: $grid-gutter-width !default;

// Drawers

// $drawer-width: 275px;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// $input-btn-font-family: null !default;

// $input-btn-padding-y: 0.75em !default;
// $input-btn-padding-x: 1.25em !default;
// $input-btn-font-size: $font-size-base !default;
// $input-btn-border-radius: $border-radius !default;
// $input-btn-line-height: $line-height-base !default;

// $input-btn-focus-width: 0.2rem !default;
// $input-btn-focus-color-opacity: 0.25 !default;
// $input-btn-focus-color: rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
// $input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

// $input-btn-padding-y-xs: $input-btn-padding-y !default;
// $input-btn-padding-x-xs: $input-btn-padding-x !default;
// $input-btn-font-size-xs: $font-size-xs !default;
// $input-btn-border-radius-xs: $border-radius-sm !default;

// $input-btn-padding-y-sm: $input-btn-padding-y !default;
// $input-btn-padding-x-sm: $input-btn-padding-x !default;
// $input-btn-font-size-sm: $font-size-sm !default;
// $input-btn-border-radius-sm: $border-radius-sm !default;

// $input-btn-padding-y-lg: $input-btn-padding-y !default;
// $input-btn-padding-x-lg: $input-btn-padding-x !default;
// $input-btn-font-size-lg: $font-size-lg !default;
// $input-btn-border-radius-lg: $border-radius-lg !default;

// $input-btn-padding-y-xl: $input-btn-padding-y !default;
// $input-btn-padding-x-xl: $input-btn-padding-x !default;
// $input-btn-font-size-xl: $font-size-xl !default;
// $input-btn-border-radius-xl: $border-radius-lg !default;

// $input-btn-border-width: $border-width !default;

// Buttons
//
// For each button, define text, background, and border color.

// $btn-font-family: $input-btn-font-family !default;
// $btn-font-weight: $font-weight-bold !default;
// $btn-white-space: null !default;
// $btn-border-width: 1px !default;
// $btn-disabled-opacity: 0.65 !default;
// $btn-line-height: $input-btn-line-height !default;

// $btn-circle-radius: 2em !default;

// $btn-padding-x: $input-btn-padding-x !default;
// $btn-padding-y: $input-btn-padding-y !default;
// $btn-font-size: $input-btn-font-size !default;
// $btn-border-radius: $input-btn-border-radius !default;

// $btn-padding-x-xs: $input-btn-padding-x-xs !default;
// $btn-padding-y-xs: $input-btn-padding-y-xs !default;
// $btn-font-size-xs: $input-btn-font-size-xs !default;
// $btn-border-radius-xs: $input-btn-border-radius-xs !default;

// $btn-padding-x-sm: $input-btn-padding-x-sm !default;
// $btn-padding-y-sm: $input-btn-padding-y-sm !default;
// $btn-font-size-sm: $input-btn-font-size-sm !default;
// $btn-border-radius-sm: $input-btn-border-radius-sm !default;

// $btn-padding-x-lg: $input-btn-padding-x-lg !default;
// $btn-padding-y-lg: $input-btn-padding-y-lg !default;
// $btn-font-size-lg: $input-btn-font-size-lg !default;
// $btn-border-radius-lg: $input-btn-border-radius-lg !default;

// $btn-padding-x-xl: $input-btn-padding-x-xl !default;
// $btn-padding-y-xl: $input-btn-padding-y-xl !default;
// $btn-font-size-xl: $input-btn-font-size-xl !default;
// $btn-border-radius-xl: $input-btn-border-radius-xl !default;

// $btn-link-color: $link-color !default;
// $btn-link-hover-color: $link-hover-color !default;
// $btn-link-disabled-color: $gray-600 !default;

// Forms

// $form-label-margin-bottom: 0.5rem !default;
// $form-label-font-size: null !default;
// $form-label-font-style: null !default;
// $form-label-font-weight: null !default;
// $form-label-color: null !default;

// $input-padding-y: $input-btn-padding-y !default;
// $input-padding-x: $input-btn-padding-x !default;
$input-font-family: $font-family-serif !default;
// $input-font-size: $input-btn-font-size !default;
// $input-font-weight: $font-weight-base !default;
// $input-line-height: $input-btn-line-height !default;

// $input-padding-y-xs: $input-btn-padding-y-xs !default;
// $input-padding-x-xs: $input-btn-padding-x-xs !default;
// $input-font-size-xs: $input-btn-font-size-xs !default;

// $input-padding-y-sm: $input-btn-padding-y-sm !default;
// $input-padding-x-sm: $input-btn-padding-x-sm !default;
// $input-font-size-sm: $input-btn-font-size-sm !default;

// $input-padding-y-lg: $input-btn-padding-y-lg !default;
// $input-padding-x-lg: $input-btn-padding-x-lg !default;
// $input-font-size-lg: $input-btn-font-size-lg !default;

// $input-padding-y-xl: $input-btn-padding-y-xl !default;
// $input-padding-x-xl: $input-btn-padding-x-xl !default;
// $input-font-size-xl: $input-btn-font-size-xl !default;

// $input-bg: $white !default;
// $input-disabled-color: $gray-600 !default;
// $input-disabled-bg: $gray-200 !default;
// $input-disabled-border-color: null !default;

$input-color: $gray-900 !default;
// $input-border-color: $gray-400 !default;
// $input-border-width: $input-btn-border-width !default;
// $input-box-shadow: null !default;

// $input-border-radius: $border-radius !default;
// $input-border-radius-xs: $border-radius-sm !default;
// $input-border-radius-sm: $border-radius-sm !default;
// $input-border-radius-lg: $border-radius-lg !default;
// $input-border-radius-xl: $border-radius-lg !default;

// $input-focus-bg: $input-bg !default;
// $input-focus-border-color: lighten($input-border-color, 25%) !default;
// $input-focus-color: $input-color !default;
// $input-focus-box-shadow: $input-btn-focus-box-shadow !default;

// $input-placeholder-color: $gray-600 !default;
// $input-plaintext-color: $body-color !default;

// $input-height-border: $input-border-width * 2 !default;

// $input-height-inner: add($input-line-height * 1em, $input-padding-y * 2) !default;
// $input-height-inner-half: add($input-line-height * 0.5em, $input-padding-y) !default;
// $input-height-inner-quarter: add($input-line-height * 0.25em, $input-padding-y / 2) !default;

// $input-height: add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
// $input-height-sm: add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
// $input-height-lg: add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;
// $input-height-xl: add($input-line-height * 1em, add($input-padding-y-xl * 2, $input-height-border, false)) !default;

// $input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

// $form-select-indicator-padding: 1em !default;
//$form-select-indicator-color: $blue !default;
// $form-select-indicator: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNNy40MSA3Ljg0TDEyIDEyLjQybDQuNTktNC41OEwxOCA5LjI1bC02IDYtNi02eiIvPg0KICAgIDxwYXRoIGQ9Ik0wLS43NWgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPg0KPC9zdmc+') !default;
// $form-select-indicator-focus: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMTA2NTk2IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJNNy40MSAxNS40MUwxMiAxMC44M2w0LjU5IDQuNThMMTggMTRsLTYtNi02IDZ6Ii8+DQogICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPg0KPC9zdmc+') !default;
// $form-select-bg-position: right ($input-padding-x / 2) center !default;
// $form-select-bg-size: null !default;

// Modals

// $modal-box-shadow: $box-shadow-lg !default;

// $modal-backdrop-bg-color: rgba($navy-dark, 0.85) !default;

// $modal-bg-color: $white !default;

// $modal-padding-x: map-get($spacers, 30) !default;
// $modal-padding-y: map-get($spacers, 30) !default;

// $modal-width-base: 600px !default;

// Popovers

// $popover-bg-color: $white;

// $popover-border-width: $border-width !default;
// $popover-border-color: $border-color !default;

// $popover-padding: $input-btn-padding-y $input-btn-padding-x !default;
// $popover-shadow: $box-shadow !default;
// $popover-width: 200px !default;

// $popover-border-radius: $input-btn-border-radius !default;
// $popover-border-radius-xs: $input-btn-border-radius-xs !default;
// $popover-border-radius-sm: $input-btn-border-radius-sm !default;
// $popover-border-radius-lg: $input-btn-border-radius-lg !default;
// $popover-border-radius-xl: $input-btn-border-radius-xl !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// $table-cell-padding: 0.5rem !default;
// $table-cell-padding-sm: 0.25rem !default;

// $table-cell-vertical-align: middle !default;

// $table-color: $body-color !default;
// $table-bg: null !default;
// $table-accent-bg: rgba($black, 0.05) !default;
// $table-hover-color: $table-color !default;
// $table-hover-bg: rgba($black, 0.075) !default;
// $table-active-bg: $table-hover-bg !default;

// $table-border-width: $border-width !default;
// $table-border-color: $border-color !default;

// $table-head-bg: $gray-200 !default;
// $table-head-color: $gray-700 !default;
// $table-head-border-color: $gray-700 !default;

// $table-dark-color: $white !default;
// $table-dark-bg: $gray-800 !default;
// $table-dark-accent-bg: rgba($white, 0.05) !default;
// $table-dark-hover-color: $table-dark-color !default;
// $table-dark-hover-bg: rgba($white, 0.075) !default;
// $table-dark-border-color: lighten($table-dark-bg, 7.5%) !default;

// $table-striped-order: odd !default;

// $table-caption-color: $gray-500 !default;

// $table-bg-level: -9 !default;
// $table-border-level: -6 !default;

// Code

// $code-font-size: $small-font-size !default;
// $code-color: $purple !default;

// $kbd-padding-y: 0.2rem !default;
// $kbd-padding-x: 0.4rem !default;
// $kbd-font-size: $code-font-size !default;
// $kbd-color: $white !default;
// $kbd-bg: $gray-900 !default;

// $pre-color: null !default;

@import '../../node_modules/@britannica/ui/src/scss/variables';
@import '../../node_modules/@britannica/ui/src/scss/mixins';

:export {
  screenXsMax: breakpoint-max(xs);
  screenSmMin: breakpoint-min(sm);
  screenSmMax: breakpoint-max(sm);
  screenMdMin: breakpoint-min(md);
  screenMdMax: breakpoint-max(md);
  screenLgMin: breakpoint-min(lg);
  screenLgMax: breakpoint-max(lg);
  screenXlMin: breakpoint-min(xl);
}

// Non-MDS global variables

$label-margin-bottom: 10px;
