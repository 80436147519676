@import '../../styles/variables';

.Footer {
  ul li {
    &:not(:last-child) {
      border-right: $border-width solid $gray-700;
      margin-right: 10px;
      padding-right: 10px;
    }

    @include media-breakpoint-down(xs) {
      &:nth-last-child(2) {
        border-right: none;
      }
    }
  }
}

.FooterAccount {
  ul li {
    & ~ li + li {
      border: none !important;
      margin-right: 0 !important;
      padding-right: 0 !important;
    }
  }
}
