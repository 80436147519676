// Required
@import 'styles/variables';
@import '~@britannica/ui/src/scss/britannica.scss';

// Helper Styles
// @import 'styles/animations';
// @import 'styles/customHelperClassUtils';

// Overrides (required)
@import 'styles/overrides';

@import 'styles/custom';

html,
body,
#root {
  height: 100%;
}

// Sticky footer
#root {
  display: flex;
  flex-direction: column;
  position: relative;

  main {
    flex: 1 0 auto;
    z-index: 10;
  }
}

img {
  max-height: 100%;
  max-width: 100%;
}
