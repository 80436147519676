@import '~@britannica/ui/src/scss/britannica-variables';

.header,
.footer {
  flex-shrink: 0;
}

.content {
  flex: 1 0 auto;
  // margin-top: 9vw;

  @include media-breakpoint-up(sm) {
    background: url(../../assets/eb_logo.svg) no-repeat top 20px center fixed;
    background-size: cover;
  }
}
