@import './variables';

.pointer {
  cursor: pointer;
}

.first-letter-uppercase:first-letter {
  text-transform: uppercase;
}

.mh-100vh {
  min-height: 100vh;
}

.card-title {
  img {
    width: 221px;
    height: auto;
    margin: 3px 0 10px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 63px;
  height: 34px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .slider {
      background-color: $blue;
      box-shadow: 0px 1px 3px rgba($black, 0.29);
      &:before {
        -webkit-transform: translateX(29px);
        -ms-transform: translateX(29px);
        transform: translateX(29px);
      }
    }
    &:focus + .slider {
      box-shadow: 0 0 1px $blue;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $gray-50;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
    &:before {
      position: absolute;
      content: '';
      height: 28px;
      width: 28px;
      left: 3px;
      bottom: 3px;
      background-color: $white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
      box-shadow: 0 3px 6px rgba($black, 0.16);
    }
  }
}
